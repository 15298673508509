@import '~antd/dist/antd.css';

$baseline: 40px;
$color-black: #404747;
$color-white: #FFF;
$color-primary: #2fcdde;

@font-face {
  font-family: 'Gotham-Medium';
  font-style: normal;
  src: url('/tipografias/Gotham-Medium.otf');
}

@font-face {
  font-family: 'Gotham-Bold';
  font-style: normal;
  src: url('/tipografias/GothamRoundedBold_0.ttf');
}

@font-face {
  font-family: 'Gotham-Light';
  font-style: normal;
  src: url('/tipografias/Gotham-Light.otf');
}

html, body {
  position: relative;
  height: 100%;
  min-height:100%;
  padding: 0;
  margin: 0;
}

html  {
  --antd-wave-shadow-color: $color-primary;
}

body {
  font-size: 16px;
  background: #efefef;
  font-family: "Gotham-Light", sans-serif;
}

h1, h2, h3 {
  line-height: 40px;
  color: $color-black;
}

h1 {
  font-family: "Gotham-Medium", sans-serif;
  font-size: 2em;
  font-weight: normal;
}

h2, h3 {
  font-family: "Gotham-Bold", sans-serif;
  font-weight: normal;
}

a{
  color: $color-primary;
}

p a {
  &:hover {
    color: $color-primary;
    border-bottom: 1px dotted $color-primary;
  }
}

.right-db-controls, .doc-db-controls {
  margin-bottom: 10px;
  @media only screen and (min-width: 1024px) {
    text-align: right;
  }
}

.iconBDMenu {
  width: 18px;
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.chart-source {
  background: rgba($color-black, .03);
  padding: calc($baseline / 4);
  margin-bottom: calc($baseline / 4);
  border-radius: 4px;
}

.ant-menu-submenu span{
  display: none;
  @media only screen and (min-width: 768px) {
    display: inline-block;
  }
}

.ant-tabs {
  margin-bottom: 30px;
}




.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(47, 205, 222, .2);
}

.ant-menu-submenu-selected, .ant-menu-item a:hover, .ant-tabs-tab:hover, .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: $color-primary;
}

.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
  color: $color-primary;
}

.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: linear-gradient(to right, $color-primary, $color-primary);
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: $color-primary;
  font-weight: bold;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid $color-primary;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: $color-primary;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: $color-primary;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-radio-button-wrapper:hover {
  color: $color-primary;
}

.ant-radio-button-wrapper:hover {
  background: rgba($color-primary, .2);
}

.ant-btn:hover, .ant-btn:focus {
  color: $color-primary;
  border-color: $color-primary;
  background: rgba($color-primary, .2);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled), .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  border-color: $color-primary!important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before, .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: $color-primary;
}

.ant-pagination-item-active a {
  color: $color-primary;
}

.ant-tabs-ink-bar {
  background: $color-primary;
}

.ant-card-head {
  padding: 0;

  @media (min-width: 992px) {
    .right-db-controls, .doc-db-controls {
      text-align: right;
    }
  }
} 

.ant-card-head-title {
  border-bottom: 0;
}

.full-screen {
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 1100;
}

.db-search-card {
  margin: 20px 0 40px;
  padding: 0.5em 1em 1em;
  background: #FAFAFA;
  border: 1px #F6F6F6 solid;
  border-radius: 0.5em;

  h1 {
    font-size: 1.6em;
    line-height: 1.5em;
  }

  h2 {
    font-size: 1.2em;
    line-height: 1.1em;
    margin-bottom: 0px;
    margin-top: 1.5em;
  }
}
